<template>
  <sign-page
    ref="page"
    title-text="发票管理"
    :request="request"
    :column-list="columnList"
    table-size="small"
    :initAddFormData="initAddFormData"
    :onFormChange="onFormChange"
    :dealFormData="dealFormData"
    @dataFileUpdate="dataFileUpdate"
    :title-menus="[{key: 'add', label: '新增'}]"
    :table-actions="tableActions"
    :form-parms-add="formParms"
    :form-parms-update="formParms"
    :table-actions-fixed="true"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  billRequest as request
} from '@/api'

let timer = null

export default {
  computed: {
    tableActions () {
      let data = [{
        key: 'data_file',
        label: '扫描件'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
      return data
    },
    formParms: {
      get () {
        let data = [{
          type: 'input',
          label: '发票代码',
          key: 'code',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '发票号码',
          key: 'codeNum',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '税前金额',
          key: 'amount',
          check: {
            rules: {
              type: 'number'
            }
          }
        },
        {
          type: 'input',
          label: '税率',
          key: 'rate',
          check: {
            rules: {
              type: 'number'
            }
          }
        },
        {
          type: 'input',
          label: '总金额',
          key: 'total',
          check: {
            rules: {
              type: 'number'
            }
          }
        },
        {
          type: 'datePicker',
          label: '开票日期',
          key: 'billTime'
        },
        {
          type: 'datePicker',
          label: '新增日期',
          key: 'addTime'
        },
        {
          type: 'input',
          label: '发票校验码',
          key: 'codeCheck'
        },
        {
          type: 'input',
          label: '发票来源',
          key: 'source'
        },
        {
          type: 'input',
          label: '货物或应税劳务、服务名称',
          key: 'projectName'
        },
        {
          type: 'textarea',
          label: '备注',
          key: 'remark'
        }]
        return data
      }
    },
    columnList () {
      return this.formParms.map(v => {
        let item = {
          title: v.label,
          field: v.key,
          sort: true
        }
        if (v.type === 'datePicker') {
          item.render = (h, rowData) => {
            return h('div', rowData && rowData[v.key] ? rowData[v.key].slice(0, 10) : '-')
          }
        }
        return item
      })
    }
  },
  methods: {
    dealFormData (data) {
      let dealKeys = ['amount', 'rate', 'total']
      dealKeys.forEach(v => {
        if (typeof data[v] === 'string') {
          data[v] = data[v].replaceAll(' ', '')
          if (data[v] === '') {
            data[v] = null
          }
        }
      })
      return data
    },
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        fileIds: parm && parm.length > 0 ? parm.join(',') : null
      })
      pageVm.loadData()
    },
    initAddFormData () {
      return {
        addTime: new Date()
      }
    },
    onFormChange (key, value, formData) {
      if (['rate', 'amount'].includes(key)) {
        let rate = Number(formData['rate'])
        let amount = Number(formData['amount'])
        if (!isNaN(rate) && !isNaN(amount) && formData['rate'] && formData['amount']) {
          return {
            total: Number((amount * (rate + 1)).toFixed(2))
          }
        } else {
          return {
            total: 0
          }
        }
      }
    },
    async update () {
      const time = await request.statusTime()
      if (this.lastTime !== null && this.lastTime !== time) {
        this.$refs.page.loadData()
      }
      this.lastTime = time
      if (timer !== false) {
        timer = setTimeout(this.update, 1000)
      }
    }
  },
  data () {
    return {
      request: request,
      lastTime: null
    }
  },
  async activated () {
    timer = setTimeout(this.update, 0)
  },
  deactivated () {
    clearTimeout(timer)
    timer = false
  }
}
</script>
